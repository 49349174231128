<template>
    <div>
        <div class="page">
            <headercomponent></headercomponent>
            <div class="site-main">
              <section class="ttm-row break-991-colum ttm-bgcolor-white clearfix">
                  <div class="container">
                     <div class="row">
                          <div class="col-lg-12">
                              <h2 class="ttm-textcolor-skincolor ">Dispatch Detail</h2>
                              <div class="mb-35" v-html="content">
                              </div>
                              <p>Published on: <span>{{cdate | moment("DD-MMM-YYYY")}}</span></p>
                          </div>
                      </div><!-- row end -->
                  </div>
              </section>
          </div>
            <footercomponent></footercomponent>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
export default {
  name: 'NewsDetail',
  props: {
    id: String
  },
    methods:{
     fetchList:function(){
             var ve = this;
             this.error = false;
             this.errorMsg = '';
              axios.get('//kakkugroup.com/hypcp10/api/dispatch/'+ve.id+'?key=7ab87646cc2756a210e38054b871feea').then((response) => {
                        ve.content = response.data.data.title;
                        ve.cdate=response.data.data.created_at
              }, () => {
                this.error = true;
                this.isLoading = false;
                //console.log(error)
              })
      },
      
  },
  watch: {
    Previous: function() {
      this.fetchList();
    }
  },
  data () {
    return {
      newid: this.id,
      msg: 'DispatchDetail',
      alertMsg:'',
      content:'',
      cdate:'',
      Previous:'',
      Next:''
    }
  },
  mounted(){
    this.id = parseInt(this.id);
    this.fetchList();
  }

}
</script>

<style scoped>

</style>
