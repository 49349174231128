import Vue from 'vue'
import App from './App.vue';
import VueRouter from 'vue-router';
import axios from 'axios';
import VueAxios from 'vue-axios';
import * as VueGoogleMaps from 'vue2-google-maps';
Vue.use(require('vue-moment'));

Vue.config.productionTip = false

import Home from '@/components/HomePage'
import Products from '@/components/Products'
import ProductDetail from '@/components/ProductDetail'
import DirectorsDesk from '@/components/Directors'
import contact from '@/components/Contact'
import achivements from '@/components/Achivements'
import customers from '@/components/Customers'
import ourreach from '@/components/OurReach'
import news from '@/components/News'
import NewsDetail from '@/components/NewsDetail'
import dispatch from '@/components/Dispatch'
import DispatchDetail from '@/components/DispatchDetail'
import header from '@/components/includes/header.vue';
import footer from '@/components/includes/footer.vue';

const router = new VueRouter({
    //mode: 'history',
    routes: [{
            path: '/',
            name: 'Home',
            component: Home
        },
        {
            path: '/products',
            name: 'Products',
            component: Products
        },
        {
            path: '/productdetail/:proid',
            name: 'ProductDetail',
            props: true,
            component: ProductDetail
        },
        {
            path: '/directordesk',
            name: 'DirectorsMessage',
            component: DirectorsDesk
        },
        {
            path: '/contact',
            name: 'ContactUs',
            component: contact
        },
        {
            path: '/achivements',
            name: 'Achivements',
            component: achivements
        },
        {
            path: '/news',
            name: 'news',
            component: news
        },
        {
            path: '/newsdetail/:id',
            name: 'NewsDetail',
            props: true,
            component: NewsDetail
        },
        {
            path: '/dispatch',
            name: 'dispatch',
            component: dispatch
        },
        {
            path: '/dispatchdetail/:id',
            name: 'DispatchDetail',
            props: true,
            component: DispatchDetail
        },
        {
            path: '/customers',
            name: 'Customers',
            component: customers
        },
        {
            path: '/ourreach',
            name: 'OurReach',
            component: ourreach
        },
    ],
    scrollBehavior() {
        return { x: 0, y: 0 }
    }
});

Vue.component('headercomponent', header);
Vue.component('footercomponent', footer);
Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyC1TuPurvcoT7LU5inQDgKkhoE-JV0dbNU',
        libraries: 'places', // This is required if you use the Autocomplete plugin
    },
});

//Vue.use(VueAxios, axios);
//axios.defaults.baseURL = '//api.originaldetectorprotector.com';
Vue.use(VueRouter);
Vue.use(VueAxios, axios);
Vue.router = router

App.router = Vue.router
App.refreshData = { enabled: true }
App.authRedirect = { path: '/' }
App.tokenStore = ['cookie']
App.cookieDomain = 'kakkugroup.com'
new Vue(App).$mount('#app')