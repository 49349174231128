<template>
    <div>
        <div class="page">
            <headercomponent></headercomponent>
            <div class="site-main">
              <section class="ttm-row break-991-colum ttm-bgcolor-white clearfix">
                  <div class="container">
                     <div class="row">
                          <div class="col-lg-12">
                              <h2 class="ttm-textcolor-skincolor ">Chairman's Message</h2>
                              <div class="mb-35">
                                  <p><img src="assets/director.jpeg" width="180px" height="150px" alt="kakku Chairman" title="kakku Chairman" style="float:left;padding:8px;"/>
                                    It gives me immense pleasure to introduce the new website of KAKKU.It has started with my vision of making India 
                                    self reliant for products which were being imported & started our endeavor as an import substitute.</p>
                                  <p>
                                    For the last over four decades , our group has been able to maintain the image of being a Symbol of reliability. I am proud to say that we are one of the rare engineering companies having most wide range of products under one roof catering to the widest range of customers from Steel plants to Mines, from power plants to cement plants, from port to railways, etc.
                                  </p>
                                  <p>
                                    Our team across the country makes us most wide spread & deeply associated with our end customer enabling us to have better understanding of customers needs & fulfilling them with best quality products & services. With massive experience & strong in house systems. I am sure that the group holds a very bright future & that it will always remain a symbol of reliability in the year to come.<br/>I humbly thank all customer for their continuous patronage & trust on us for years together our associates & the complete team at KAKKU for their contribution in the establishment & growth.
                                  </p>
                                  <p class="ttm-textcolor-skincolor "><b>Mr. Rajesh Bahadur Mathur</b></p>
                                  <small><strong>Chairman, Kakku Groups</strong></small>

                              </div>
                          </div>
                      </div><!-- row end -->
                  </div>
              </section>
          </div>
            <footercomponent></footercomponent>
        </div>
    </div>
</template>
<script>
export default {
  name: 'DirectorsMessage',
  props: {
    id: String
  },
  data () {
    return {
      msg: 'Directors'
    }
  }

}
</script>

<style scoped>

</style>
