<template>
    <div>
        <div class="page">
            <headercomponent></headercomponent>
            <div class="site-main">
                
        <!--intro-section-->
        <section class="ttm-row intro-section style2 bg-layer break-1199-colum ttm-bgcolor-white clearfix">
            <div class="container">
                <div class="row">
                    <div class="col-lg-3 col-md-12">
                        <!-- about-img -->
                        <div class="about-content ttm-col-bgcolor-yes ttm-bg ttm-left-span pt-65 pb-35">
                            <div class="ttm-col-wrapper-bg-layer ttm-bg-layer ttm-bgcolor-grey"></div>
                            <div class="layer-content">
                                <div class="about-img about-2 mb-35 mr_200 res-1200-mr-0">
                                    <img class="img-fluid" src="assets/certificate.jpg" alt="">
                                </div>
                            </div>
                        </div>
                        <!-- about-img end -->
                    </div>
                    <div class="col-lg-9 col-md-12">
                        <div class="row">
                            <div class="col-lg-3"></div>
                            <div class="col-lg-9">
                                <div class="pl-15 pt-55">
                                    <!-- section title -->
                                    <div class="section-title clearfix">
                                        <h2 class="title">Our<strong>Achivements</strong></h2>
                                        <div class="heading-seperator"><span></span></div>
                                        <p><strong>Skill India - Maximum Apprentices Award</strong></p>
                                    </div><!-- section title end -->
                                    <p class="mt_15">Government Of India Awarded Kakku Electronic & Power Control Company for engaging maximum apprentices in the category of Central Public Sector Units/ State Public Sector Units/ Pvt. Ltd/ Micro Small Medium Enterprises.</p>
                                    <h6 class="about-author-name">Ministry of Skill Development & Entrepreneurship</h6>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div><!-- row end -->
            </div>
        </section>
        <!-- intro-section end -->
            </div>
            <footercomponent></footercomponent>
        </div>
    </div>
</template>
<script>
export default {
  name: 'Achivements',
  props: {
    id: String
  },
  data () {
    return {
      msg: ''
    }
  }

}
</script>

<style scoped>

</style>
