<template>
  <div>
    <div class="page">
      <headercomponent></headercomponent>
        <!-- page-title -->
            <div class="ttm-page-title-row" style="background-image: url(assets/img/products-banner.jpg) !important;">
                <div class="section-overlay"></div>
                <div class="container">
                    <div class="row">
                        <div class="col-md-12"> 
                            <div class="title-box">
                                <div class="page-title-heading">
                                    <h1 class="title">{{ProductTitle}}</h1>
                                </div>  
                            </div>
                        </div>
                    </div> 
                </div>                      
            </div><!-- page-title end-->
        <!--site-main start-->
        <div class="site-main">

        <!-- intro-section -->
        <section class="ttm-row pt-82 pb-50 ttm-bgcolor-grey clearfix">
            <div class="container">
                <!-- row -->
                <div class="row">
                    <div class="col-lg-12">
                        <div class="box-shadow ttm-pf-single-content-wrapper-innerbox ttm-pf-view-left-image">
                            <div class="row">
                                <div class="col-lg-4 col-md-4 col-sm-4">
                                    <figure class="gallery-item">
                                            <img class="resize-image img-fluid" :src="ProductImage" alt="blog-img">
                                    </figure>
                                </div>
                               
                            </div>
                            <div class="row">
                                <div class="col-lg-8">
                                    <div class="ttm-portfolio-description pt-10">
                                        <h2 class="ttm-pf-detailbox-title">Product Details</h2>
                                        <p v-html="ProductDescription">{{ProductDescription}}<br></p>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="ttm-pf-single-detail-box">
                                        <div class="ttm-pf-detailbox">
                                            <ul class="ttm-pf-detailbox-list">
                                                <li class="ttm-pf-details-date" v-bind:value="item.id" :id="item.rowid" v-for="(item, index) in Feed" :key="index">
                                                    <i class="fa fa-file-pdf-o"></i>
                                                    <span class="ttm-pf-left-details">{{item.title}}</span>
                                                    <a :href="item.file" target="_blank"><span class="ttm-pf-right-details">Download</span></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    
                                </div>  
                            </div>
                        </div>
                    </div>
                </div><!-- row end -->
            </div>
        </section>
        <!-- intro-section end -->
    </div>
        <footercomponent></footercomponent>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'Products',
  props: ['proid'],
  methods:{
     fetchList:function(){
             var ve = this;
             this.error = false;
             this.errorMsg = '';
             this.Feed = [];
             this.feedItems = {};
              axios.get('//kakkugroup.com/hypcp10/api/product/'+ve.proid+'?key=7ab87646cc2756a210e38054b871feea').then((response) => {
                    ve.feedItems = response.data.data
                        ve.ProductTitle = response.data.data.title;
                        ve.ProductImage = 'assets/img/product_images/'+ve.proid+'.png'
                        ve.ProductDescription = response.data.data.description;

                        response.data.data.downloads.map(function(itmx){
                            var filename = itmx.name.split("file/");
                                var feeddata = 
                                {
                                    id: itmx.id,
                                    title: filename[1],
                                    file: '//kakkugroup.com/assets/'+itmx.path
                                }
                            ve.Feed.push(feeddata)
                        }); 

              }, () => {
                this.error = true;
                this.isLoading = false;
                //console.log(error)
              })
      },
      
  },
  watch: {
    Previous: function() {
      this.fetchList();
    }
  },
  data () {
    return {
      id:'',
      msg: 'Products',
      alertMsg:'',
      Feed:[],
      feedItems:{},
      ProductTitle:'',
      ProductImage:'',
      ProductDescription:'',
      Previous:'',
      Next:''
    }
  },
  mounted(){
    this.id = parseInt(this.proid);
    this.fetchList();
  }

}
</script>

<style scoped>

</style>
