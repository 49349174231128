<template>
    <div>
        <div class="page">
            <headercomponent></headercomponent>
            <div class="site-main">
              <section class="ttm-row break-991-colum ttm-bgcolor-white clearfix">
                  <div class="container">
                     <div class="row">
                          <div class="col-lg-12">
                              <h2 class="ttm-textcolor-skincolor ">Our Reach</h2>
                              <p>Being a symbol of reliability, we are expanding our reach globaly with each passing day. we keep striving to deliver reliable & innovative products to empower industries like Iron & Steels, Cement Manufacturing, Coal Mines, Defence and Mechanical industries around the globe.</p>
                              <br/>
                              
                              <div class="mb-35">
                                  <gmap-map
                                    :center="center"
                                    :zoom="2"
                                    style="width:100%;  height: 400px;"
                                    >
                                    <gmap-marker
                                       v-for="(m, index) in markers"
                                        :position="m.position"
                                        :clickable="true" :draggable="true"
                                        @click="center=m.position"
                                        :key="index"
                                        @mouseover="statusText = m.text"
                                        @mouseout="statusText = null"
                                    ></gmap-marker>
                                    </gmap-map>

                              </div>
                          </div>
                      </div><!-- row end -->
                  </div>
              </section>
          </div>
            <footercomponent></footercomponent>
        </div>
    </div>
</template>
<script>
export default {
  name: 'OurReach',
  props: {
    id: String
  },
 data() {
    return {
      // default to Montreal to keep it simple
      // change this to whatever makes sense
      center: { lat: 21.234171, lng: 81.376790 },
      statusText: '',
      markers: [
          {
            position: {
              lat: 21.1458004,
              lng: 81.376790
            },
            icon: {url : "http://kakkugroup.com/assets/img/marker.png"},
            type: 'info',
            text: 'KAKKU E&P, INDIA'
          },
          {
            position: {
              lat: 6.8751909,
              lng: 80.01184839999999
            },
            text: 'Ceylon Heavy Industries and Construction Company Ltd'
          },
          {
            position: {
              lat: 25.2048493,
              lng: 55.2707828
            },
            text: 'KUHNEZUG AG CRANE MANUFACTURING'
          }, {
            position: {
              lat: 24.4333555,
              lng: 56.6261379
            },
            text: 'Jindal Shadeed Iron & Steel'
          },
          {
            position: {
              lat: -26.7034204,
              lng: 27.8076959
            },
            text: 'ATLAS ELECTRO-HYDRAULIC SYSTEMS (P) LTD'
          },
          {
            position: {
              lat: -6.2335134,
              lng: 107.1071
            },
            text: 'PT Essar Indonesia'
          },
          {
            position: {
              lat: 6.9381743,
              lng: 79.8604831
            },
            text: 'Melwire rolling pvt ltd'
          },
          {
            position: {
              lat: 37.062415,
              lng: 36.145216
            },
            text: 'PLATINUM DEMİR CELİK SANAYİ VE TİCARET A.S.'
          },
          {
            position: {
              lat: 14.716677,
              lng: -17.4676861
            },
            text: 'FABRIMETAL SENEGAL'
          },
          {
            position: {
              lat: 22.356851,
              lng: 91.7831819
            },
            text: 'BSRM Steel Mills Limited'
          }
      ],
      places: [],
      currentPlace: null
    };
  },
   mounted() {
    //this.geolocate();
  },

   methods: {
    // receives a place object via the autocomplete component
    setPlace(place) {
      this.currentPlace = place;
    },
    addMarker() {
      if (this.currentPlace) {
        const marker = {
          lat: this.currentPlace.geometry.location.lat(),
          lng: this.currentPlace.geometry.location.lng()
        };
        this.markers.push({ position: marker });
        this.places.push(this.currentPlace);
        this.center = marker;
        this.currentPlace = null;
      }
    },
    geolocate: function() {
      navigator.geolocation.getCurrentPosition(position => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
      });
    }
  }


}
</script>

<style scoped>

</style>
