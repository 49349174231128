<template>
    <div>
        <div class="page">
            <headercomponent></headercomponent>
            <div class="site-main">
                <section class="ttm-row break-991-colum bg-layer res-991-p-0 clearfix">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-9">
                            <div class="ttm-col-bgcolor-yes ttm-bg ttm-bgcolor-darkgrey padding-8">
                                    <div class="ttm-col-wrapper-bg-layer ttm-bg-layer"></div>
                                    <div class="layer-content row m-0">
                                        <div class="col-md-10">
                                            <!-- section title -->
                                            <div class="section-title clearfix">
                                                <h2 class="title">Send<strong>Us Message!</strong></h2>
                                                <div class="heading-seperator"><span></span></div>
                                                <p>Please fill out the request for quote form below and our expert team will get back to you shortly.</p>
                                            </div><!-- section title end -->
                                            <form id="contactform" class="contactform style2 wrap-form clearfix" novalidate="novalidate" @submit.prevent="contactus" method="post">
                                                <label>
                                                    <i class="ti ti-user"></i>
                                                    <span class="ttm-form-control"><input class="text-input" name="name" type="text" v-model="name" placeholder="Name:" required="required"></span>
                                                </label>
                                                <label>
                                                    <i class="ti ti-email"></i>
                                                    <span class="ttm-form-control"><input class="text-input" name="email" type="text" v-model="email" placeholder="Email:" required="required"></span>
                                                </label>
                                                <label>
                                                    <i class="ti ti-headphone"></i>
                                                    <span class="ttm-form-control"><input class="text-input" name="phone" type="text" v-model="number" placeholder="Number:" required="required"></span>
                                                </label>
                                                <label>
                                                    <i class="ti ti-comment"></i>
                                                    <span class="ttm-form-control"><textarea class="text-area" name="message" placeholder="Message:" v-model="message" required="required"></textarea></span>
                                                </label>
                                                <label>
                                                    <vue-recaptcha ref="recaptcha" @verify="onCaptchaVerified" @expired="onCaptchaExpired"  sitekey="6LeSQqYUAAAAAF2b7bctLacHFXTV2w4P10A3i0M1"></vue-recaptcha>
                                                </label>
                                                <input name="submit" type="submit" value="Submit Now!" class="ttm-btn ttm-btn-size-md  ttm-btn-style-border ttm-btn-color-white" id="submit" title="Submit now"/>
                                            </form>
                                        </div>
                                        <div class="col-md-2"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <!-- testimonial-box -->
                                <div class="ttm-col-bgcolor-yes ttm-bg ttm-bgcolor-skincolor padding-8 ml_170 res-991-ml-0 mt-100 res-991-mt-0">
                                    <div class="ttm-col-wrapper-bg-layer ttm-bg-layer"></div>
                                    <div class="layer-content">
                                        <h2 class="ttm-textcolor-white mb-20">Drop in our office</h2>
                                        <!--featured-box-->
                                        <div class="featured-box style9 left-icon">
                                            <div class="featured-icon icon-white"> 
                                                <i class="ti ti-mobile"></i>
                                            </div>
                                            <div class="featured-content">
                                                <div class="featured-title">
                                                    <h5>Phone</h5>
                                                </div>
                                                <div class="featured-desc">
                                                    <p>+91-9893561615<br/>
                                                    +91-788-2280610<br/>
                                                    +91-788-2283297
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="featured-box style9 left-icon">
                                            <div class="featured-icon icon-white"> 
                                                <i class="ti ti-printer"></i>
                                            </div>
                                            <div class="featured-content">
                                                <div class="featured-title">
                                                    <h5>Fax</h5>
                                                </div>
                                                <div class="featured-desc">
                                                    <p>+91-788-2281797</p>
                                                </div>
                                            </div>
                                        </div>
                                        <!--featured-box-->
                                        <div class="featured-box style9 left-icon">
                                            <div class="featured-icon icon-white"> 
                                                <i class="ti ti-email"></i>    
                                            </div>
                                            <div class="featured-content">
                                                <div class="featured-title">
                                                    <h5>Email</h5>
                                                </div>
                                                <div class="featured-desc">
                                                    <p>
                                                        kakku_bhilai@yahoo.com <br/>
                                                        sales@kakkuindia.com
                                                    </p>
                                                </div>
                                            </div>
                                        </div><!-- featured-box end-->
                                        <!--featured-box-->
                                        <div class="featured-box style9 left-icon">
                                            <div class="featured-icon icon-white"> 
                                                <i class="ti ti-location-pin"></i>        
                                            </div>
                                            <div class="featured-content">
                                                <div class="featured-title">
                                                    <h5>Corporate Office</h5>
                                                </div>
                                                <div class="featured-desc">
                                                    <p>93, Industrial Estate, Bhilai- 490026<br/>Chhattisgarh, India</p>
                                                </div>
                                            </div>
                                        </div><!-- featured-box end-->
                                        <!--featured-box-->
                                    </div>
                                </div>
                            </div>
                        </div><!-- row end -->
                    </div>
                </section>
                <div class="map-wrapper">
                    <GmapMap 
										:center="{ lat: 21.234171, lng: 81.376790 }"
										:zoom="17"
										style="width: 100%; height: 100%"
										
									>
										<GmapMarker
											:key="index"
											v-for="(m, index) in markers"
											:position="m.position"
											:clickable="true"
											:draggable="true"
											@click="center=m.position"
										/>
									</GmapMap>
                </div>
            </div>
            <footercomponent></footercomponent>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import VueRecaptcha from 'vue-recaptcha';
export default {
    methods: {
        
        contactus(){
            if(this.captcharesponse == ''){
                alert("Please verify captcha");
                return false;
            }
             var ve = this;
              axios.post('http://kakkugroup.com/submitquery.php',{name:ve.name,email:ve.email,number:ve.number,message:ve.message,captchacode:ve.captcharesponse}).then((response) => {
                  if(response.data.success){
                      ve.successMessage = response.data.message;
                      alert(response.data.message);
                      ve.formClear();
                  }else{
                      alert(response.data.message);
                  }
                ///ve.feedItems = response.data.data.items
                
              }, () => {
                this.error = true;
              })
        },
        onCaptchaVerified: function (recaptchaToken){
            this.captcharesponse = recaptchaToken;
        },
        onCaptchaExpired: function () {
        this.$refs.recaptcha.reset();
        },
        formClear(){
            this.$refs.recaptcha.reset();
            this.name = '';
            this.number = '';
            this.email = '';
            this.message = '';
        }
    },
    components:{
    VueRecaptcha 
	},
    data:function(){
      return {
        name:'',
        email:'',
        number:'',
        message:'',
        captcharesponse:'',
        successMessage:'',
        errorMessage:'',
        markers: [{
            position: {
                    lat: 21.234171,
                    lng: 81.376790,
                }
        }],
        places: [],
      }
    },
    mounted(){
     
    }
}
</script>
