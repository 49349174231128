<template>
    <div>
        <div class="page">
            <headercomponent></headercomponent>
            <div class="site-main">
                
        <!--intro-section-->
        <section class="ttm-row intro-section style2 bg-layer break-1199-colum ttm-bgcolor-white clearfix">
            <div class="container">
                <div>
                     <div class="pl-15 pt-55">
                        <!-- section title -->
                        <div class="section-title clearfix">
                            <h2 class="title"><strong>Latest News</strong></h2>
                            <div class="heading-seperator"><span></span></div>
                        </div><!-- section title end -->
                        <ul>
                            <li v-bind:value="item.id" :id="item.rowid" v-for="(item, index) in Feed" :key="index">
                                <router-link :to="{name:'NewsDetail',params:{id:item.id}}"><strong>{{item.title}}</strong></router-link>
                            </li>
                        </ul>
                        
                    </div>
                </div>
            </div>
        </section>
        <!-- intro-section end -->
            </div>
            <footercomponent></footercomponent>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
export default {
  name: 'Achivements',
  props: {
    id: String
  },
  methods:{
     fetchList:function(){
             var ve = this;
             this.error = false;
             this.errorMsg = '';
             this.Feed = [];
             this.feedItems = {};
              axios.get('//kakkugroup.com/hypcp10/api/news?key=7ab87646cc2756a210e38054b871feea').then((response) => {
                    ve.feedItems = response.data.data
                        response.data.data.map(function(idx){
                        var feeddata = 
                        {
                            id: idx.id,
                            title: idx.title,
                            description: idx.content,
                        }
                        ve.Feed.push(feeddata) 
                        });

              }, () => {
                this.error = true;
                this.isLoading = false;
                //console.log(error)
              })
      },
  },
  data () {
    return {
      msg: 'Products',
      alertMsg:'',
      Feed:[],
      feedItems:{},
    }
  },
  mounted(){
    this.fetchList();
  }

}
</script>

