<template>
    <div>
        <div class="page">
            <headercomponent></headercomponent>
            <div class="site-main">
                <section class="ttm-row client-section2 ttm-bgcolor-white clearfix">
                    <div class="container">
                        <!-- row -->
                        <div class="row text-center">
                            <div class="col-md-12">
                                <!-- section title -->
                                <div class="section-title clearfix">
                                    <div class="section-title">
                                    <h2 class="title">Serving <strong> Globally </strong></h2>
                                    <div class="heading-seperator text-center"><span></span></div>
                                </div><!-- section-title end -->
                                </div><!-- section title end -->
                                 <p>
                                    We are bound to serve excellence to our services. We strive to reach WOW level each and everytime by delivering quality on what we deliver to our customers.
                                </p>
                            </div>
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-sm-12 col-md-3" v-for="index in 22" :key="index">
                                        <div class="client-box">
                                        <div class="client">
                                            <div class="ttm-client-logo-tooltip">
                                                <img :src="'assets/client-logos/'+index+'.png'" alt="image">
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div><!-- row end -->
                    </div>
                </section>
            </div>
            <footercomponent></footercomponent>
        </div>
    </div>
</template>
<script>
export default {
  name: 'Customers',
  props: {
    id: String
  },
  data () {
    return {
      msg: ''
    }
  }

}
</script>

<style scoped>

</style>
