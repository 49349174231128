<template>
    <div>
        <div class="hwrap" v-if="Feed.length > 0">
            <div class="d-flex justify-content-between align-items-center breaking-news">
                <div class="d-flex flex-row flex-grow-1 flex-fill justify-content-center text-white">
                    <div class="news">Recent Dispatch:</div>
                </div>
                <marquee class="news-scroll" behavior="scroll" direction="left" onmouseover="this.stop();" onmouseout="this.start();"> 
                    <span v-bind:value="item.id" :id="item.rowid" v-for="(item, index) in Feed" :key="index">
                        <router-link :to="{name:'DispatchDetail',params:{id:item.id}}">{{item.cdate | moment("DD-MMM-YYYY")}} - {{item.caption}}</router-link> 
                    </span>
                    |
                    </marquee>
            </div>
        </div>
        <footer class="footer widget-footer clearfix">
            <div class="first-footer bg-img7 ttm-bg ttm-bgimage-yes ttm-bgcolor-darkgrey">
                <div class="ttm-row-wrapper-bg-layer ttm-bg-layer"></div>
                <div class="container">
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-4 widget-area">  
                        <div class="widget widget-out-link clearfix">
                                <i class="fa fa-phone"></i>
                                <h3 class="widget-title">Have a question? call us now</h3>
                                <p>Call: +91-788-2280610<br>Fax: +91-788-2281797</p>
                                <router-link :to="{name:'ContactUs'}" class="ttm-btn btn-inline style2">Contact Us</router-link>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-4 widget-area">  
                        <div class="widget widget-out-link clearfix">
                                <i class="fa fa-envelope-o"></i>
                                <h3 class="widget-title">Need support? Drop us an email</h3>
                                <p>kakku_bhilai@yahoo.com<br>sales@kakkuindia.com</p>
                                <router-link :to="{name:'ContactUs'}" class="ttm-btn btn-inline style2">Product Inquiry</router-link>
                            </div>
                        </div> 
                        
                        <div class="col-xs-12 col-sm-12 col-md-4 widget-area">
                            <div class="widget widget-out-link clearfix">
                                <i class="fa fa-clock-o"></i>
                                <h3 class="widget-title">We are open on</h3>
                                <p>Mon – Sat 09:00 – 18:00<br>Sunday – Closed</p>
                                <router-link :to="{name:'ContactUs'}" class="ttm-btn btn-inline style2">View Location</router-link>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
            <div class="bottom-footer-text ttm-bottom-footer-text ttm-bgcolor-darkgrey ttm-textcolor-white">
                        <div class="container">
                            <div class="row copyright">
                                <div class="col-md-4">
                                    <div class=" ttm-footer2-left">
                                    <span>Copyright © 2019&nbsp;<a href="#">KAKKU E&P CONTROL (P) LTD</a></span></div>
                                </div>
                                <div class="col-md-4">
                                    Visitors: <img src="https://hitwebcounter.com/counter/counter.php?page=7855823&style=0036&nbdigits=9&type=ip&initCount=158626" title="Free Counter" Alt="web counter"   border="0" />
                                </div>
                                <div class="col-md-4 ttm-footer2-right">
                                    Site By: <a href="//hyphunsofttech.com" target="_blank">Hyphun</a>
                                </div>
                            </div>
                        </div>
                    </div>
            
        </footer>
    </div>
</template>    
<script>
import axios from 'axios';
export default {
  name: 'Footer',
  methods:{
     fetchList:function(){
             var ve = this;
             this.error = false;
             this.errorMsg = '';
             this.Feed = [];
             this.feedItems = {};
              axios.get('//kakkugroup.com/hypcp10/api/dispatch?key=7ab87646cc2756a210e38054b871feea').then((response) => {
                    ve.feedItems = response.data.data
                        response.data.data.map(function(idx){
                        var feeddata = 
                        {
                            id: idx.id,
                            title: idx.title,
                            caption: idx.caption,
                            cdate: idx.created_at
                        }
                        ve.Feed.push(feeddata) 
                        });

              }, () => {
                this.error = true;
                this.isLoading = false;
                //console.log(error)
              })
      },
  },
  data () {
    return {
      msg: 'Products',
      alertMsg:'',
      Feed:[],
      feedItems:{},
    }
  },
  mounted(){
    this.fetchList();
  }

}
</script>