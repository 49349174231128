<template>
  <div>
    
    <!--page start-->
    <div class="page">

        <!-- preloader start -->
        <div id="preloader" style="display: none;">
          <div id="status" style="display: none;">&nbsp;</div>
        </div>
        <!-- preloader end -->

        <headercomponent></headercomponent>
       

        <div id="rev_slider_4_1_wrapper" class="rev_slider_wrapper fullwidthbanner-container slide-overlay" data-alias="classic4export" data-source="gallery">

            <!-- START REVOLUTION SLIDER 5.4.8 auto mode -->
            <div id="rev_slider_4_1" class="rev_slider fullwidthabanner" data-version="5.4.8">
                <div class="slotholder"></div>
                <ul>
                    
                    <li data-index="rs-11" data-transition="fade" data-slotamount="default" data-hideafterloop="0" data-hideslideonmobile="off" data-easein="default" data-easeout="default" data-masterspeed="300" data-rotate="0" data-saveperformance="off" data-title="Slide" data-param1="" data-param2="" data-param3="" data-param4="" data-param5="" data-param6="" data-param7="" data-param8="" data-param9="" data-param10="" data-description="">
                        <img src="assets/img/slider/slider1.jpg" alt="" title="slider-mainbg-002" width="1920" height="725" data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat" class="rev-slidebg" data-no-retina>

                        <div class="tp-caption haeding-slide heilight-white-text" id="slide-11-layer-15" data-x="['left','left','left','center']" data-hoffset="['50','40','30','0']" data-y="['top','top','top','middle']" data-voffset="['179','179','51','-90']"
                            data-fontsize="['90','90','65','45']"
                            data-lineheight="['100','100','75','55']"
                            data-fontweight="['600','600','600','600']"
                            data-width="none"
                            data-height="none"
                            data-whitespace="nowrap"
                            data-type="text"
                            data-responsive_offset="on"
                            data-frames='[{"delay":240,"speed":1500,"frame":"0","from":"x:[175%];y:0px;z:0;rX:0;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:1;","mask":"x:[-100%];y:0;s:inherit;e:inherit;","to":"o:1;","ease":"Power3.easeOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                            data-textAlign="['inherit','inherit','inherit','inherit']"
                            data-paddingtop="[0,0,0,0]"
                            data-paddingright="[0,0,0,0]"
                            data-paddingbottom="[0,0,0,0]"
                            data-paddingleft="[0,0,0,0]">We Build 
                        </div>
                        <div class="tp-caption haeding-slide heilight-white-text" id="slide-11-layer-16" data-x="['left','left','left','center']" data-hoffset="['50','40','30','0']" data-y="['top','top','top','middle']" data-voffset="['265','265','113','-39']"
                            data-fontsize="['90','90','65','45']"
                            data-lineheight="['100','100','75','55']"
                            data-fontweight="['600','600','600','600']"
                            data-width="none"
                            data-height="none"
                            data-whitespace="nowrap"
                            data-type="text"
                            data-responsive_offset="on"
                            data-frames='[{"delay":750,"speed":1500,"frame":"0","from":"x:[175%];y:0px;z:0;rX:0;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:1;","mask":"x:[-100%];y:0;s:inherit;e:inherit;","to":"o:1;","ease":"Power3.easeOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                            data-textAlign="['inherit','inherit','inherit','inherit']"
                            data-paddingtop="[0,0,0,0]"
                            data-paddingright="[0,0,0,0]"
                            data-paddingbottom="[0,0,0,0]"
                            data-paddingleft="[0,0,0,0]">The <strong>Great</strong> Future
                        </div>
                        <a class="tp-caption tm-simple-link " href="#" target="_self" id="slide-11-layer-17" data-x="['center','center','center','center']" data-hoffset="['-521','-421','-316','10']" data-y="['middle','middle','middle','middle']" data-voffset="['31','31','-6','21']"
                            data-fontsize="['20','20','18','16']"
                            data-lineheight="['28','28','28','22']"
                            data-width="none"
                            data-height="none"
                            data-whitespace="nowrap"
                            data-visibility="['on','on','on','off']"
                            data-type="text"
                            data-actions=''
                            data-responsive_offset="on"
                            data-frames='[{"delay":1160,"speed":460,"frame":"0","from":"y:50px;opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                            data-textAlign="['inherit','inherit','inherit','inherit']"
                            data-paddingtop="[0,0,0,0]"
                            data-paddingright="[0,0,0,0]"
                            data-paddingbottom="[0,0,0,0]"
                            data-paddingleft="[0,0,0,0]">Best Ideas 
                        </a>
                        <div class="tp-caption tp-dash-shape tp-shapewrapper " id="slide-11-layer-18" data-x="['center','center','center','center']" data-hoffset="['-454','-354','-254','10']" data-y="['middle','middle','middle','middle']" data-voffset="['31','31','-6','21']"
                            data-width="7"
                            data-height="3"
                            data-whitespace="nowrap"
                            data-visibility="['on','on','on','off']"
                            data-type="shape"
                            data-responsive_offset="on"
                            data-frames='[{"delay":1160,"speed":460,"frame":"0","from":"y:50px;opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                            data-textAlign="['inherit','inherit','inherit','inherit']"
                            data-paddingtop="[0,0,0,0]"
                            data-paddingright="[0,0,0,0]"
                            data-paddingbottom="[0,0,0,0]"
                            data-paddingleft="[0,0,0,0]"> 
                        </div>
                        <a class="tp-caption tm-simple-link " href="#" target="_self" id="slide-11-layer-19" data-x="['center','center','center','center']" data-hoffset="['-371','-271','-176','10']" data-y="['middle','middle','middle','middle']" data-voffset="['31','31','-6','21']"
                            data-fontsize="['20','20','18','16']"
                            data-lineheight="['28','28','28','22']"
                            data-width="none"
                            data-height="none"
                            data-whitespace="nowrap"
                            data-visibility="['on','on','on','off']"
                            data-type="text"
                            data-actions=''
                            data-responsive_offset="on"
                            data-frames='[{"delay":1160,"speed":460,"frame":"0","from":"y:50px;opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                            data-textAlign="['inherit','inherit','inherit','inherit']"
                            data-paddingtop="[0,0,0,0]"
                            data-paddingright="[0,0,0,0]"
                            data-paddingbottom="[0,0,0,0]"
                            data-paddingleft="[0,0,0,0]">Best Solutions 
                        </a>
                        <div class="tp-caption tp-dash-shape tp-shapewrapper " id="slide-11-layer-20" data-x="['center','center','center','center']" data-hoffset="['-286','-186','-96','10']" data-y="['middle','middle','middle','middle']" data-voffset="['31','31','-6','21']"
                            data-width="7"
                            data-height="3"
                            data-whitespace="nowrap"
                            data-visibility="['on','on','on','off']"
                            data-type="shape"
                            data-responsive_offset="on"
                            data-frames='[{"delay":1160,"speed":460,"frame":"0","from":"y:50px;opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                            data-textAlign="['inherit','inherit','inherit','inherit']"
                            data-paddingtop="[0,0,0,0]"
                            data-paddingright="[0,0,0,0]"
                            data-paddingbottom="[0,0,0,0]"
                            data-paddingleft="[0,0,0,0]"> 
                        </div>
                        <a class="tp-caption tm-simple-link " href="#" target="_self" id="slide-11-layer-21" data-x="['center','center','center','center']" data-hoffset="['-217','-117','-31','10']" data-y="['middle','middle','middle','middle']" data-voffset="['31','31','-6','21']"
                            data-fontsize="['20','20','18','16']"
                            data-lineheight="['28','28','28','22']"
                            data-width="none"
                            data-height="none"
                            data-whitespace="nowrap"
                            data-visibility="['on','on','on','off']"
                            data-type="text"
                            data-actions=''
                            data-responsive_offset="on"
                            data-frames='[{"delay":1160,"speed":460,"frame":"0","from":"y:50px;opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                            data-textAlign="['inherit','inherit','inherit','inherit']"
                            data-paddingtop="[0,0,0,0]"
                            data-paddingright="[0,0,0,0]"
                            data-paddingbottom="[0,0,0,0]"
                            data-paddingleft="[0,0,0,0]">Best Result 
                        </a>
                        <div class="tp-caption desc-slide" id="slide-11-layer-22" data-x="['left','left','left','center']" data-hoffset="['50','40','30','0']" data-y="['middle','middle','middle','middle']" data-voffset="['90','90','44','21']"
                            data-fontsize="['16','16','14','13']"
                            data-lineheight="['28','28','28','22']"
                            data-width="none"
                            data-height="none"
                            data-whitespace="nowrap"
                            data-type="text"
                            data-responsive_offset="on"
                            data-frames='[{"delay":950,"speed":1500,"frame":"0","from":"x:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                            data-textAlign="['left','left','left','center']"
                            data-paddingtop="[0,0,0,0]"
                            data-paddingright="[0,0,0,0]"
                            data-paddingbottom="[0,0,0,0]"
                            data-paddingleft="[0,0,0,0]">We offer the most complete industrial solutions in the country, for the<br> research, design and development of materials. 
                        </div>
                        <a class="tp-caption classic-border-button " href="#" target="_self" id="slide-1-layer-18" data-x="['left','left','left','center']" data-hoffset="['50','40','30','0']" data-y="['middle','middle','middle','middle']" data-voffset="['190','190','124','72']"
                            data-fontsize="['16','16','14','12']"
                            data-lineheight="['16','16','14','12']"
                            data-width="none"
                            data-height="none"
                            data-whitespace="nowrap"
                            data-type="text"
                            data-actions=''
                            data-responsive_offset="on"
                            data-frames='[{"delay":1320,"speed":500,"frame":"0","from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","mask":"x:0px;y:[100%];s:inherit;e:inherit;","to":"o:1;","ease":"Power0.easeIn"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"},{"frame":"hover","speed":"500","ease":"Power0.easeIn","to":"o:1;rX:0;rY:0;rZ:0;z:0;","style":"c:rgb(3,15,41);bg:rgb(255,255,255);bc:transparent;br:0px 0px 0px 0px;"}]'
                            data-textAlign="['inherit','inherit','inherit','inherit']"
                            data-paddingtop="[18,18,15,12]"
                            data-paddingright="[30,30,25,20]"
                            data-paddingbottom="[18,18,15,12]"
                            data-paddingleft="[30,30,25,20]">More Services
                        </a>

                        <a class="tp-caption video-icon ttm_prettyphoto" href="" target="_self" id="slide-11-layer-13" data-x="['left','left','center','center']" data-hoffset="['249','240','-156','45']" data-y="['middle','middle','middle','middle']" data-voffset="['190','190','124','115']"
                            data-fontsize="['18','18','15','14']"
                            data-lineheight="['50','50','50','30']"
                            data-letterspacing="['0','0','1','1']"
                            data-width="['50','50','50','30']"
                            data-height="['50','50','50','30']"
                            data-whitespace="nowrap"
                            data-type="text"
                            data-actions=''
                            data-responsive_offset="on"
                            data-frames='[{"delay":1490,"speed":500,"frame":"0","from":"y:50px;opacity:0;","to":"o:1;","ease":"Power0.easeIn"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                            data-textAlign="['center','center','center','center']"
                            data-paddingtop="[,,,]"
                            data-paddingright="[,,,]"
                            data-paddingbottom="[,,,]"
                            data-paddingleft="[,,,]"><i class="fa fa-play"></i>
                        </a>
                        <div class="tp-caption simple-text" id="slide-11-layer-24" data-x="['left','left','center','center']" data-hoffset="['312','315','-73','-20']" data-y="['middle','middle','middle','middle']" data-voffset="['190','190','124','115']"
                            data-fontsize="['16','16','14','12']"
                            data-lineheight="['20','20','15','15']"
                            data-width="none"
                            data-height="none"
                            data-whitespace="nowrap"
                            data-type="text"
                            data-actions=''
                            data-responsive_offset="on"
                            data-frames='[{"delay":1470,"speed":500,"frame":"0","from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","mask":"x:0px;y:[100%];s:inherit;e:inherit;","to":"o:1;","ease":"Power0.easeIn"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","e":"Power3.easeInOut"}]'
                            data-textAlign="['inherit','inherit','inherit','inherit']"
                            data-paddingtop="[20,20,15,12]"
                            data-paddingright="[0,0,0,0]"
                            data-paddingbottom="[20,20,15,12]"
                            data-paddingleft="[0,0,0,0]">How We Work
                        </div> 
                    </li>
                    <li data-index="rs-1" data-transition="fade" data-slotamount="default" data-hideafterloop="0" data-hideslideonmobile="off" data-easein="default" data-easeout="default" data-masterspeed="300" data-rotate="0" data-saveperformance="off" data-title="Slide" data-param1="" data-param2="" data-param3="" data-param4="" data-param5="" data-param6="" data-param7="" data-param8="" data-param9="" data-param10="" data-description="">

                        <img src="assets/img/slider/slider2.jpg" alt="" title="slider-mainbg-001" width="1920" height="725" data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat" class="rev-slidebg" data-no-retina>

                        <div class="tp-caption haeding-slide" id="slide-3-layer-5" data-x="['right','right','right','center']" data-hoffset="['50','40','30','0']" data-y="['top','top','top','middle']" data-voffset="['179','179','51','-90']"
                            data-fontsize="['90','90','65','45']"
                            data-lineheight="['100','100','75','55']"
                            data-fontweight="['600','600','600','600']"
                            data-width="none"
                            data-height="none"
                            data-whitespace="nowrap"
                            data-type="text"
                            data-responsive_offset="on"
                            data-frames='[{"delay":240,"speed":1500,"frame":"0","from":"x:[-175%];y:0px;z:0;rX:0;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:1;","mask":"x:[100%];y:0;s:inherit;e:inherit;","to":"o:1;","ease":"Power3.easeOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                            data-textAlign="['inherit','inherit','inherit','inherit']"
                            data-paddingtop="[0,0,0,0]"
                            data-paddingright="[0,0,0,0]"
                            data-paddingbottom="[0,0,0,0]"
                            data-paddingleft="[0,0,0,0]">Innovative 
                        </div>
                        <div class="tp-caption haeding-slide" id="slide-3-layer-6" data-x="['right','right','right','center']" data-hoffset="['50','50','30','0']" data-y="['top','top','top','middle']" data-voffset="['265','265','113','-39']"
                            data-fontsize="['90','90','65','45']"
                            data-lineheight="['100','100','75','55']"
                            data-fontweight="['600','600','600','600']"
                            data-width="none"
                            data-height="none"
                            data-whitespace="nowrap"
                            data-type="text"
                            data-responsive_offset="on"
                            data-frames='[{"delay":240,"speed":1500,"frame":"0","from":"x:[175%];y:0px;z:0;rX:0;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:1;","mask":"x:[-100%];y:0;s:inherit;e:inherit;","to":"o:1;","ease":"Power3.easeOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                            data-textAlign="['inherit','inherit','inherit','inherit']"
                            data-paddingtop="[0,0,0,0]"
                            data-paddingright="[0,0,0,0]"
                            data-paddingbottom="[0,0,0,0]"
                            data-paddingleft="[0,0,0,0]">
                            Business <strong>Solution</strong></div>
                        <div class="tp-caption desc-slide" id="slide-3-layer-7" data-x="['right','right','right','center']" data-hoffset="['50','40','30','0']" data-y="['middle','middle','middle','middle']" data-voffset="['41','56','6','21']"
                            data-fontsize="['16','16','14','13']"
                            data-lineheight="['28','28','28','22']"
                            data-width="none"
                            data-height="none"
                            data-whitespace="nowrap"
                            data-type="text"
                            data-responsive_offset="on"
                            data-frames='[{"delay":950,"speed":1500,"frame":"0","from":"x:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                            data-textAlign="['right','right','right','center']"
                            data-paddingtop="[0,0,0,0]"
                            data-paddingright="[0,0,0,0]"
                            data-paddingbottom="[0,0,0,0]"
                            data-paddingleft="[0,0,0,0]">
                            We offer the most complete industrial solutions in the country, for the<br> research, design and development of materials. 
                        </div>
                        <a class="tp-caption highlight-skin-button" href="#" target="_self" id="slide-4-layer-8" data-x="['right','right','right','center']" data-hoffset="['50','40','30','0']" data-y="['top','top','middle','middle']" data-voffset="['478','482','91','120']" 
                            data-fontsize="['16','16','14','12']"
                            data-lineheight="['16','16','14','12']"
                            data-width="none"
                            data-height="none"
                            data-whitespace="nowrap"
                            data-type="text"
                            data-actions=''
                            data-responsive_offset="on"
                            data-frames='[{"delay":1470,"speed":500,"frame":"0","from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","mask":"x:0px;y:[100%];s:inherit;e:inherit;","to":"o:1;","ease":"Power0.easeIn"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","e":"Power3.easeInOut"}]'
                            data-textAlign="['inherit','inherit','inherit','inherit']"
                            data-paddingtop="[16,16,15,12]"
                            data-paddingright="[30,30,25,20]"
                            data-paddingbottom="[16,16,15,12]"
                            data-paddingleft="[30,30,25,20]">Our Industries 
                        </a>

                        <a class="tp-caption classic-border-button" href="#" target="_self" id="slide-4-layer-9" data-x="['right','right','right','center']" data-hoffset="['243','233','200','0']" data-y="['top','top','middle','middle']" data-voffset="['477','481','91','70']"
                            data-fontsize="['16','16','14','12']"
                            data-lineheight="['16','16','14','12']"
                            data-width="none"
                            data-height="none"
                            data-whitespace="nowrap"
                            data-type="text"
                            data-actions=''
                            data-responsive_offset="on"
                            data-frames='[{"delay":1320,"speed":500,"frame":"0","from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","mask":"x:0px;y:[100%];s:inherit;e:inherit;","to":"o:1;","ease":"Power0.easeIn"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"},{"frame":"hover","speed":"500","ease":"Power0.easeIn","to":"o:1;rX:0;rY:0;rZ:0;z:0;","style":"c:rgb(3,15,41);bg:rgb(255,255,255);bc:transparent;br:0px 0px 0px 0px;"}]'
                            data-textAlign="['inherit','inherit','inherit','inherit']"
                            data-paddingtop="[16,16,15,12]"
                            data-paddingright="[30,30,25,20]"
                            data-paddingbottom="[16,16,15,12]"
                            data-paddingleft="[30,30,25,20]">Our Products
                            </a>
                    </li>
                </ul>
            <div class="tp-loader off"><div class="dot1"></div><div class="dot2"></div><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div><div class="tp-bannertimer"></div></div>

        </div>
       

        <div class="site-main">

            <!--welcome-section-->
            <section class="ttm-row welcome-section break-1199-colum ttm-bgcolor-white clearfix">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6">
                            <!-- about-img -->
                            <div class="about-img about-2 mb-10">
                                <img class="img-fluid" src="assets/img/kakku_images/front-mix-img.png" alt="about-img">
                                <!-- image-overlay-content -->
                                <div class="image-overlay-content">
                                    <div class="circle-shape ttm-top-view-overlay ttm-bgcolor-skincolor">
                                        <span class="flaticon flaticon-factory-2"></span>
                                        <p>Founded in  1969</p>
                                    </div>
                                </div><!-- image-overlay-content end -->
                            </div><!-- about-img end -->
                        </div>
                        <div class="col-lg-6">
                            <div class="about-info pr-10 res-1199-plr-0">
                                <!-- section title -->
                                <div class="section-title clearfix">
                                    <h2 class="title" style="font-size: 29px; !important"><strong>KAKKU E&P Control (P) LTD</strong></h2>
                                    <div class="heading-seperator"><span></span></div>
                                    <p><strong>Electronic & Power Control Company (An Enterprise of Kakku E & P Control Pvt Ltd.)</strong>, Bhilai, was founded in the year 1968 with a clear vision of "Make in India" through Import Substitution of EOT Crane electrics required by Core sector Industries both in India and abroad. It was started in the year 1968 by a young entrepreneur Rajesh Bahadur Mathur, a BE (Electrical) from the illustrious BIT’s Pilani of 1967 batch, who led the Industry into Engineering, Manufacturing, Technology and Service. Electronic & Power Control Company trademarked its products under the brand name of "KAKKU" and in no time this brand name became a symbol of Reliability, quality, durability and precision. Actually this brand name was the nickname of Rajesh Bahadur Mathur himself.</p>
                                    <p>Steadily but surely, the “KAKKU” tag made its presence felt in major Industries and its Product range which began with Electrical Relays, forayed into Control & Selector Switches, Conveyor Safety Switches , Master Controllers, Limit Switches, AC & DC Electro Magnetic Brakes, Lifting Electromagnets, Resistance Boxes, Crane Cabins, Armchair Control Desk and for various type of Cranes. Besides Cranes, Commander Controllers required for Blast Furnace and Rolling Mill applications for Steel Plants were also added to the product range. The complete Product range is reflected in the Company Brochure.</p>
                                </div><!-- section title end -->
                                <!-- row-->
                                <div class="row">
                                    <div class="col-lg-8">
                                        <h4>WE ARE SYMBOL OF RELIABLITY</h4>
                                    </div>
                                    <div class="col-lg-4"></div>
                                    <div class="col-lg-12">
                                        <div class="featured-box style10 left-icon">
                                            <div class="featured-icon icon-shape-square ttm-bgcolor-skincolor"> 
                                                <i class="ti ti-headphone"></i>
                                            </div>
                                            <div class="featured-content ttm-bgcolor-darkgrey">
                                                <div class="featured-desc">
                                                    <p>Let's Call Us to get a Free Estimate</p>
                                                    <h3>+91-788-2283297</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div><!-- row end-->
                            </div>
                        </div>
                    </div><!-- row end -->
                </div>
            </section>
            <!-- welcome-section end -->


            <!--about-section-->
            <section class="ttm-row about-section break-991-colum bg-layer ttm-bgcolor-darkgrey mt-130 res-991-mt-0 clearfix">
                <div class="container">
                    <!-- row -->
                    <div class="row">
                        <div class="col-md-7">
                            <!-- about-content -->
                            <div class="about-content ttm-col-bgcolor-yes ttm-bg ttm-left-span ttm-bgcolor-darkgrey padding-1">
                                <div class="ttm-col-wrapper-bg-layer ttm-bg-layer"></div>
                                <div class="layer-content">
                                    <!-- section title -->
                                    <div class="section-title clearfix">
                                        <h5>WHAT WE DO</h5>
                                        <h2 class="title">Get Premium<strong>Industrial Products</strong></h2>
                                        <div class="heading-seperator"><span></span></div>
                                        <p>KAKKU has 49+ years of experience with providing wide area of specialty services works listed below. A Company involved in servicing, maintenance and repairs of widest-range of Control Gears and Electro-mechanical Control Equipments, used in diverse application in a wide array of industries.</p>
                                    </div><!-- section title end -->
                                    <div class="separator mb-20">
                                        <div class="sep-line"></div>
                                    </div>
                                    <!-- row-->
                                 
                                </div>
                            </div><!-- about-content end-->
                        </div>
                        <div class="col-md-5 mtb_40 res-991-mtb-0">
                            <!-- col-bg-img-one -->
                            <div class="col-bg-img-one ttm-col-bgimage-yes ttm-bg mr_90 res-1460-mr_0">
                                <div class="ttm-col-wrapper-bg-layer ttm-bg-layer" style="background-image: url(assets/img/kakku_images/front-second.jpg) !important;"></div>
                                <div class="layer-content">
                                    <!-- image-overlay-content -->
                                    <div class="image-overlay-content">
                                        <div class="rectangle-shape ttm-bottom-view-overlay ttm-bgcolor-skincolor">
                                            <span>Team Working Dedicatedly</span>
                                            <p>KAKKU has high skilled team of dedicated hard-working professionals to serve it's customers better.</p>
                                        </div>
                                    </div><!-- image-overlay-content end -->
                                </div>
                            </div><!-- col-bg-img-one end-->
                            <img src="assets/img/kakku_images/front-second.jpg" class="ttm-equal-height-image" alt="bg-image">
                        </div>
                    </div><!-- row end -->
                </div>
            </section>
            <!-- about-section end -->

            <!-- service-section -->
            <section class="ttm-row service-section style2 clearfix">
                <div class="container">
                    <!-- row -->
                    <div class="row">
                        <div class="col-lg-9 col-md-12">
                            <!-- section-title -->
                            <div class="section-title style2 clearfix">
                                <h2 class="title">Technical<strong>Industrial Solutions</strong></h2>
                                <p>KAKKU has 50+ years of experience with providing wide area of specialty services works listed below.</p>
                            </div><!-- section-title end -->
                        </div>
                        
                    </div><!-- row end -->
                    <div class="row row-equal-height">
                        <div class="col-lg-4 col-md-6 col-sm-12">
                            <!-- featured-box -->
                            <div class="featured-box style2 text-center">
                                <div class="featured-icon"> 
                                    <i class="flaticon flaticon-factory-4"></i>
                                </div>
                                <div class="featured-content">
                                    <div class="featured-title">
                                        <h5>Steel & Iron Industry</h5>
                                    </div>
                                    <div class="featured-desc">
                                        <p>We have strength and experience in this services which allowed in successfully delivering projects to number of clients.</p>
                                    </div>
                                </div>
                            </div><!-- featured-box end-->
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12">
                            <!-- featured-box -->
                            <div class="featured-box style2 text-center">
                                <div class="featured-icon"> 
                                    <i class="flaticon flaticon-loader"></i>
                                </div>
                                <div class="featured-content">
                                    <div class="featured-title">
                                        <h5>Coal Mines Industry</h5>
                                    </div>
                                    <div class="featured-desc">
                                        <p>Since 1969 Kakku manufacturing services have manufactured many products for Coal Mines Industry.</p>
                                    </div>
                                </div>
                            </div><!-- featured-box end-->
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12">
                            <!-- featured-box -->
                            <div class="featured-box style2 text-center">
                                <div class="featured-icon"> 
                                    <i class="flaticon flaticon-factory-2"></i>
                                </div>
                                <div class="featured-content">
                                    <div class="featured-title">
                                        <h5>Cement Manufacturing</h5>
                                    </div>
                                    <div class="featured-desc">
                                        <p>We provide embedded technology with innovation and digital capabilities to transform industrial functions.</p>
                                    </div>
                                </div>
                            </div><!-- featured-box end-->
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12">
                            <!-- featured-box -->
                            <div class="featured-box style2 text-center">
                                <div class="featured-icon"> 
                                    <i class="flaticon flaticon-security"></i>
                                </div>
                                <div class="featured-content">
                                    <div class="featured-title">
                                        <h5>Defence Sector</h5>
                                    </div>
                                    <div class="featured-desc">
                                        <p>Our main goal is to get major growth in nation security sector for better Defence industry products.</p>
                                    </div>
                                </div>
                            </div><!-- featured-box end-->
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12">
                            <!-- featured-box -->
                            <div class="featured-box style2 text-center">
                                <div class="featured-icon"> 
                                    <i class="flaticon flaticon-conveyor"></i>
                                </div>
                                <div class="featured-content">
                                    <div class="featured-title">
                                        <h5>Mechanical Engineering</h5>
                                    </div>
                                    <div class="featured-desc">
                                        <p>Kakku will provide to different industry with a full suite of mechanical engineering solutions.</p>
                                    </div>
                                </div>
                            </div><!-- featured-box end-->
                        </div>
                        
                    </div><!-- row end-->
                </div>
            </section>
            <!-- service-section end -->

            <!--portfolio-section-->
            <section class="ttm-row portfolio-section2">
                <div class="container">
                    <!-- row -->
                    <div class="row">
                        <div class="col-lg-9 col-md-12">
                            <!-- section-title -->
                            <div class="section-title style2 clearfix">
                                <h2 class="title">Our<strong>Products</strong></h2>
                                <p>KAKKU has 50+ years of experience with providing wide area of specialty services works listed below.</p>
                            </div><!-- section-title end -->
                        </div>
                        <div class="col-lg-3 col-md-12">
                        <router-link :to="{name:'Products'}"><a class="ttm-btn ttm-btn-size-md ttm-btn-shape-square ttm-btn-style-border ttm-btn-color-black mt-30 mb-35 res-991-mt-0 float-right" href="javascript://">View All Products</a></router-link>
                        </div>
                    </div><!-- row end -->
                    <!-- row -->
                    <div class="row pt-10 pl-5 pr-5 res-991-mt-0 multi-columns-row ttm-boxes-spacing-10px ttm-bgcolor-white box-shadow">
                        <div class="ttm-box-col-wrapper col-lg-4 col-md-6">
                            <!-- featured-item -->
                            <div class="featured-item featured-portfolio-item">
                                <div class="featured-thumbnail">
                                    <a href=""> <img class="resize-image img-fluid" src="assets/img/product_images/2.png" alt="image"></a>
                                </div>
                                <!-- ttm-box-view-overlay -->
                                <div class="ttm-box-view-overlay ttm-portfolio-box-view-overlay">
                                    <div class="content-post content-portfolio-post">
                                        <router-link :to="{name:'ProductDetail',params:{proid:2}}"><h2 class="title-post"><a href="javascript://">Lifting Electro Magnets</a></h2></router-link>
                                    </div>
                                </div><!-- ttm-box-view-overlay end-->
                            </div><!-- featured-item -->
                        </div>
                        <div class="ttm-box-col-wrapper col-lg-4 col-md-6">
                            <!-- featured-item -->
                            <div class="featured-item featured-portfolio-item">
                                <div class="featured-thumbnail">
                                    <a href=""> <img class="resize-image img-fluid" src="assets/img/product_images/3.png" alt="image"></a>
                                </div>
                                <!-- ttm-box-view-overlay -->
                                <div class="ttm-box-view-overlay ttm-portfolio-box-view-overlay">
                                    <div class="content-post content-portfolio-post">
                                        <router-link :to="{name:'ProductDetail',params:{proid:3}}"><h2 class="title-post"><a href="javascript://">Master Controllers</a></h2></router-link> 
                                    </div>
                                </div><!-- ttm-box-view-overlay end-->
                            </div><!-- featured-item -->
                        </div>
                        <div class="ttm-box-col-wrapper col-lg-4 col-md-6">
                            <!-- featured-item -->
                            <div class="featured-item featured-portfolio-item">
                                <div class="featured-thumbnail">
                                    <div class="featured-thumbnail">
                                    <a href=""><img class="resize-image img-fluid" src="assets/img/product_images/8.png" alt="image"></a>
                                </div>
                                </div>
                                <!-- ttm-box-view-overlay -->
                                <div class="ttm-box-view-overlay ttm-portfolio-box-view-overlay">
                                    
                                    <div class="content-post content-portfolio-post">
                                        <router-link :to="{name:'ProductDetail',params:{proid:8}}"><h2 class="title-post"><a href="javascript://">Crane Cabins</a></h2></router-link>
                                    </div>
                                </div><!-- ttm-box-view-overlay end-->
                            </div><!-- featured-item -->
                        </div>
                        <div class="ttm-box-col-wrapper col-lg-4 col-md-6">
                            <!-- featured-item -->
                            <div class="featured-item featured-portfolio-item">
                                <div class="featured-thumbnail">
                                    <a href=""> <img class="resize-image img-fluid" src="assets/img/product_images/5.png" alt="image"></a>
                                </div>
                                <!-- ttm-box-view-overlay -->
                                <div class="ttm-box-view-overlay ttm-portfolio-box-view-overlay">
                                   <div class="content-post content-portfolio-post">
                                        <router-link :to="{name:'ProductDetail',params:{proid:5}}"><h2 class="title-post"><a href="javascript://">Contactors (DC) & (AC) Clapper type</a></h2></router-link>
                                    </div>
                                </div><!-- ttm-box-view-overlay end-->
                            </div><!-- featured-item -->
                        </div>
                        <div class="ttm-box-col-wrapper col-lg-4 col-md-6">
                            <!-- featured-item -->
                            <div class="featured-item featured-portfolio-item">
                                <div class="featured-thumbnail">
                                    <a href=""><img class="resize-image img-fluid" src="assets/img/product_images/16.png" alt="image"></a>
                                </div>
                                <!-- ttm-box-view-overlay -->
                                <div class="ttm-box-view-overlay ttm-portfolio-box-view-overlay">
                                    <div class="content-post content-portfolio-post">
                                        <router-link :to="{name:'ProductDetail',params:{proid:16}}"><h2 class="title-post"><a href="javascript://">AC Gong Bells</a></h2></router-link>
                                    </div>
                                </div><!-- ttm-box-view-overlay end-->
                            </div><!-- featured-item -->
                        </div>
                        <div class="ttm-box-col-wrapper col-lg-4 col-md-6">
                            <!-- featured-item -->
                            <div class="featured-item featured-portfolio-item">
                                <div class="featured-thumbnail">
                                    <a href=""><img class="resize-image img-fluid" src="assets/img/product_images/6.png" alt="image"></a>
                                </div>
                                <!-- ttm-box-view-overlay -->
                                <div class="ttm-box-view-overlay ttm-portfolio-box-view-overlay">
                                    <div class="featured-iconbox ttm-media-link">
                                        
                                    </div>
                                    <div class="content-post content-portfolio-post">
                                        <router-link :to="{name:'ProductDetail',params:{proid:6}}"><h2 class="title-post"><a href="javascript://">Joystick Controllers</a></h2></router-link>
                                    </div>
                                </div><!-- ttm-box-view-overlay end-->
                            </div><!-- featured-item -->
                        </div>
                    </div><!-- row end -->
                </div>
            </section>
            <!--portfolio-section end-->



       
            
        </div>

        <footercomponent></footercomponent>

        <!--back-to-top start-->
        <a id="totop" href="#top" style="display: none;" class="">
            <i class="fa fa-angle-up"></i>
        </a>
        <!--back-to-top end-->

    </div><!-- page end -->

  </div>
</template>

<script>
export default {
  name: 'HomePage',
  props: {
    id: String
  },
  data () {
    return {
      msg: 'FirstRouteChild'
    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
