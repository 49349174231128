<template>
     <!--header start-->
        <header id="masthead" class="header ttm-header-style-infostack">
            <!-- ttm-topbar-wrapper -->
            <div class="ttm-topbar-wrapper ttm-bgcolor-darkgrey ttm-textcolor-white clearfix">
                <div class="container">
                    <div class="ttm-topbar-content">
                        <ul class="top-contact text-left">
                            <li class="list-inline-item">WE ARE SYMBOL OF RELIABLITY</li>
                        </ul>
                        <div class="topbar-right text-right">
                            
                            <ul class="top-contact ttm-highlight">
                                <li><strong><i class="fa fa-phone"></i>Talk To Expert :</strong> <span class="tel-no">+91-9893561615</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div><!-- ttm-topbar-wrapper end -->

            <!-- ttm-header-wrap -->
            <div class="ttm-header-wrap">
                <div class="container">
                    <div class="ttm-header-top-wrapper clearfix">
                    <!-- site-branding -->
                        <div class="site-branding">
                             <router-link :to="{ name: 'Home'}">
                                 <a class="home-link" href="" title="Kakku" rel="home"> 
                                    <img id="logo-img" class="img-center" src="assets/img/logo-img.png" alt="logo-img">
                                 </a>
                             </router-link>
                        </div><!-- site-branding end -->
                        <!-- ttm-top-info-con-->
                        <div class="ttm-top-info-con clearfix">
                            <ul>
                                <li>
                                    <div class="icon-box icon-style-border">
                                        <i class="ti ti-mobile"></i>    
                                    </div>
                                    <div class="title-box">
                                        <h5>Call</h5> 
                                        <p>+91-788-2283297</p>   
                                    </div>
                                </li>
                                <li>
                                    <div class="icon-box icon-style-border">
                                        <i class="ti ti-comment-alt"></i>    
                                    </div>
                                    <div class="title-box">
                                        <h5>Email</h5> 
                                        <p>sales@kakkuindia.com</p>   
                                    </div>
                                </li>
                                <li>
                                    <div class="icon-box icon-style-border">
                                        <i class="ti ti-location-pin"></i>    
                                    </div>
                                    <div class="title-box">
                                        <h5>Address</h5> 
                                        <p>93, Industrial Estate, Bhilai- 490026, CG, India</p>   
                                    </div>
                                </li>     
                            </ul>
                        </div>
                        <!-- ttm-top-info-con end -->
                    </div>
                    <!-- ttm-stickable-header-w -->
                    <div id="ttm-stickable-header-w" class="ttm-stickable-header-w clearfix">
                        <div id="site-header-menu" class="site-header-menu">
                                <div class="site-header-menu-inner ttm-stickable-header visible-title">
                                    <div class="container">
                                    <!--site-navigation -->
                                    <div id="site-navigation" class="site-navigation">
                                        <div class="ttm-menu-toggle">
                                            <input type="checkbox" id="menu-toggle-form">
                                            <label for="menu-toggle-form" class="ttm-menu-toggle-block">
                                                <span class="toggle-block toggle-blocks-1"></span>
                                                <span class="toggle-block toggle-blocks-2"></span>
                                                <span class="toggle-block toggle-blocks-3"></span>
                                            </label>
                                        </div>
                                        <nav id="menu" class="menu">
                                            <ul class="dropdown">
                                                <li>
                                                    <router-link :to="{ name: 'Home'}">Home</router-link>
                                                </li>
                                                <li >
                                                    <router-link :to="{ name: 'Products'}">Products</router-link>
                                                </li>
                                                <li >
                                                    <router-link :to="{ name: 'Customers'}">Customers</router-link>
                                                </li>
                                                <li >
                                                    <router-link :to="{ name: 'news'}">News</router-link>
                                                </li>
                                                <li >
                                                    <router-link :to="{ name: 'dispatch'}">Dispatch</router-link>
                                                </li>
                                                <li>
                                                <router-link :to="{ name: 'ContactUs'}">Contact us</router-link>
                                                </li>
                                                <li >
                                                    <router-link :to="{ name: 'DirectorsMessage'}">Chairman's Message</router-link>
                                                </li>
                                                <li class="has-submenu">
                                                   <a href=""> More</a>
                                                    <ul class="dropdown">
                                                        <li>
                                                            <router-link :to="{ name: 'OurReach'}">Our Reach</router-link>
                                                        </li>
                                                        
                                                        <li >
                                                            <router-link :to="{ name: 'Achivements'}">Achivments</router-link>
                                                        </li>

                                                        <li >
                                                            <a href="https://www.youtube.com/channel/UCYtitPelTLSXTh1zmlzfrnw/featured" target="_blank">Video Gallery</a>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                    <div class="ttm-rt-contact">
                                        <div class="ttm-custombutton">
                                            <router-link :to="{ name: 'ContactUs'}"><a href="javascript://">Get a Quote<i class="fa fa-caret-right"></i></a></router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div><!--ttm-header-wrap end -->

        </header>
        <!--header end-->
</template>
<script>
export default {
    methods: {
     
    },
    data:function(){
      return {
      }
    },
    mounted(){
     
    }
}
</script>