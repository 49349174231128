<template>
  <div>
    <div class="page">
      <headercomponent></headercomponent>
        <!-- page-title -->
            <div class="ttm-page-title-row" style="background-image: url(assets/img/products-banner.jpg) !important;">
                <div class="section-overlay"></div>
                <div class="container">
                    <div class="row">
                        <div class="col-md-12"> 
                            <div class="title-box">
                                <div class="page-title-heading">
                                    <h1 class="title">Our Products</h1>
                                </div>  
                            </div>
                        </div>
                    </div> 
                </div>                      
            </div><!-- page-title end-->
        <!--site-main start-->
        <div class="site-main">

            <!-- intro-section -->
            <section class="ttm-row pt-65 pb-60 ttm-bgcolor-grey clearfix">
                <div class="container">
                    <!-- row -->
                    <div class="row">
                        <div class="col-lg-12">
                            <h2><strong>Our Products</strong></h2>
                            <p class="mb-50">Below you’ll see our all products we are manufacturing.&nbsp;Never ending customer support also provide guarantee a very high level of satisfaction for our clients.&nbsp;&nbsp;if you have a question&nbsp; don’t hesitate to email us at <a href="#">sales@kakkuindia.com</a>&nbsp;for more details.</p>
                        </div>
                    </div><!-- row end -->
                    <!-- row -->
                    <div class="row">
                        <div class="col-lg-4 col-md-6" v-bind:value="item.id" :id="item.rowid" v-for="(item, index) in Feed" :key="index">
                            <!-- featured-item -->
                            <router-link :to="{name:'ProductDetail',params:{proid:item.id}}"><div class="featured-item ttm-portfolio-view-topimage">
                                <div class="featured-portfolio-item ttm-item-view-topimage">
                                    <div class="featured-thumbnail">
                                        <a href="#"> <img class="resize-image img-fluid" :src="item.imgsrc" alt="image"></a>
                                    </div>
                                </div>
                                <div class="content-post">
                                    <h6 class="title-post"><a href="#">{{item.title}}</a></h6>
                                </div>
                            </div></router-link>
                            <!-- featured-item end-->
                        </div>
                    </div><!-- row end -->
                </div>
            </section>
            <!-- intro-section end -->
            
        </div><!--site-main end-->
        <footercomponent></footercomponent>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'Products',
  props: {
    id: String
  },
  methods:{
     fetchList:function(){
             var ve = this;
             this.error = false;
             this.errorMsg = '';
             this.Feed = [];
             this.feedItems = {};
              axios.get('//kakkugroup.com/hypcp10/api/products?key=7ab87646cc2756a210e38054b871feea').then((response) => {
                    ve.feedItems = response.data.data
                        var i = 1;
                        response.data.data.map(function(idx){
                        var feeddata = 
                        {
                            id: idx.pgid,
                            title: idx.name,
                            description: idx.description,
                            imgsrc: 'assets/img/product_images/'+i+'.png'
                        }
                        i++;
                        ve.Feed.push(feeddata) 
                        });

              }, () => {
                this.error = true;
                this.isLoading = false;
                //console.log(error)
              })
      },
  },
  data () {
    return {
      msg: 'Products',
      alertMsg:'',
      Feed:[],
      feedItems:{},
    }
  },
  mounted(){
    this.fetchList();
  }

}
</script>

<style scoped>

</style>
